import React from 'react';
import { applications } from './apps';
import { Application } from './model/Application';
import './Home.css';

const Home: React.FC = () => {
  return (
    <div className="app-list">
      <br/>
        {applications.map((app: Application) => (
            <div className='app-card'>
            <h2>{app.name}</h2><p>{app.desc}</p>
            </div>
          
        ))}
      
    </div>
  );
}

export default Home;
