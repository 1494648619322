import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Home from './Home';
import logo from "./assets/aptive_logo.png";
import './App.css';

interface Token {
  access_token: string;
  [key: string]: any; // If the token contains additional properties
}

const App: React.FC = () => {
  const [token, setToken] = useState<Token | null>(null);
  const [user, setUser] = useState();
  const serverUrl = process.env.REACT_APP_URL;

  useEffect(() => {
    const cookieToken = Cookies.get('access_token');
    if (cookieToken) {
      let parsedAccessToken: Token = JSON.parse(cookieToken);
      const tokenExpiry = Cookies.get('expires_at');
      const current = new Date().toISOString();
      if(current < JSON.stringify(tokenExpiry)){
        const refresh_token = Cookies.get('refresh_token');
        fetch(`${serverUrl}/refresh?refresh_token=${refresh_token}`).then(resp => {
          resp.json().then(token => parsedAccessToken = token.token);
        })
      }
      setToken(parsedAccessToken);
      var options = { headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${parsedAccessToken}`
        }
      };
      fetch('https://graph.microsoft.com/oidc/userinfo', options).then(response => {
        response.json().then(user => setUser(user));
      })
    }
  }, []); // `token` is not needed as a dependency since you want to set it once on mount

  const handleLogin = () => {
    window.location.href = `${serverUrl}/auth/login`;
  };

  const handleLogout = () => {
    window.location.href = `${serverUrl}/auth/logout`;
  };

  
    return (
      <div className='App'><img src={logo} className="App-logo" alt="logo" /><div className='App-header'>
        <h1>Welcome {user?.given_name} to aptive internal tools home</h1>
        {token ? <Home /> : <div>
          <button className="button" onClick={handleLogin}>Login</button>
        </div>}
      </div></div>
    );
  }

export default App;
