import { Application } from "./model/Application";


export const applications : Application[] = [
    {name : "People Operations", desc: "" },
    {name : "Projects Report", desc: "" },
    {name : "Sharepoint Analiyzer", desc: "" },
    {name : "Ask Archie", desc: "" },
    {name : "Resume Cards", desc: "" },
    {name : "Deal Tracker", desc: "" }
]